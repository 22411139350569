<template>
  <div>
    <div class="heading">{{ name }}</div>
    <el-checkbox-group
        v-model="inputVal"
        class="checkboxes"
    >
      <label
          v-if="all"
          class="el-checkbox"
          :class="[inputVal.length === 0 ? 'is-checked': '', ]"
          @click="handleAll"
      >
        <span aria-checked="mixed"
              class="el-checkbox__input">
          <span class="el-checkbox__inner">
          </span><input type="checkbox"
                        aria-hidden="true" class="el-checkbox__original"
                        value="All"></span>
        <span class="el-checkbox__label">
        All</span></label>
      <el-checkbox
          v-for="choice in optionsDisplay"
          :label="choice"
          :key="choice"
          border
      >
        {{choice}}
      </el-checkbox>
    </el-checkbox-group>
  </div>
</template>

<script>

export default {
  name: 'CheckBoxGroup',
  props: {
    choices: Array, // can be [object or string]
    name: String,
    value: Array,
    all: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    optionsDisplay() {
      const { choices } = this;
      const choicesDisplay = [];
      if (typeof choices[0] === 'string') {
        choices.forEach(
          x => choicesDisplay.push(x),
        );
      } else if (typeof choices[0] === 'object') {
        choices.forEach(
          x => choicesDisplay.push(x.name),
        );
      }
      return choicesDisplay;
    },
  },
  data() {
    return {
      inputVal: this.value,
    };
  },
  methods: {
    handleAll() {
      this.$set(this, 'inputVal', []);
    },
  },
  watch: {
    inputVal(val) {
      let data = val;
      if (this.name === 'Age' && data.length > 1) {
        data.shift();
      }
      if (data.length === this.choices.length) {
        data = [];
      }

      this.inputVal = data;
      this.$emit('input', data);
    },
  },
};
</script>

<style lang="scss" scoped>

  .heading {
    margin: 5px 15px 0px;
  }

  .checkboxes {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    ::v-deep label {
      border-radius: 0;
      border: 1px solid lightgrey;
      text-align: center;
      font-size: 1rem;
      display: inline-block;
      margin: 15px 0 0 15px !important;
      padding: 20px 5% 40px;
      /*min-height: 70px;*/
      flex-grow: 1;
      width: calc(100% * (1 / 3) - 15px - 1px);
      max-width: calc(100% * (1 / 3) - 15px - 1px);
    }

    ::v-deep .el-checkbox {
      padding-top: 0;
      padding-bottom: 0;
      height: 50px;

      // truncating lines
      // https://css-tricks.com/line-clampin/
      overflow: hidden;
    }

    // affect children deeply with this
    ::v-deep .el-checkbox__input {
      display: none; // hide the checkbox
    }

    ::v-deep .el-checkbox__label {
      font-size: medium;
      padding-left: 0;
      color: $primary;
      white-space: normal;

      // https://www.w3schools.com/howto/howto_css_center-vertical.asp
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);

      // https://css-tricks.com/line-clampin/
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    .is-checked {
      border: 2px solid black;
    }
  }


</style>
