<template>
  <div class="d-flex-column">
    <CheckBoxGroup
      :choices="memberDisplayType"
      name="Participant Type"
      v-model="inputVal.participantTypes"
    />
    <CheckBoxGroup
      :choices="activity"
      name="Activity"
      v-model="inputVal.activities"
    />
    <CheckBoxGroup
      :choices="gender"
      name="Gender"
      v-model="inputVal.genders"
    />
    <CheckBoxGroup
      :choices="ageLevels"
      name="Age"
      v-model="inputVal.ages"
    />
    <CheckBoxGroup
      :choices="competitionType"
      name="Competition Type"
      v-model="inputVal.competitionTypes"
    />

    <div class="mt-2 d-flex-column">
      <div>
        <el-button
            @click='submit'
            type="primary"
            class="full-width">Apply Filters</el-button>
      </div>
      <div class="mt-1">
        <el-button
            @click="clear"
            class="full-width button-invisible"
        >Clear</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import CheckBoxGroup from '@/components/elements/CheckBoxGroup.vue';
import {
  activity, competitionType, gender, memberDisplayType, ageLevels,
} from '@/utils/constants';

export default {
  name: 'FilterPage',
  components: { CheckBoxGroup },
  props: ['value'],
  methods: {
    submit() {
      this.$emit('submit', this.inputVal);
    },
    clear() {
      this.$emit('clear');
    },
    cancel() {
      this.$emit('cancel');
    },
  },
  data() {
    return {
      memberDisplayType,
      activity,
      gender,
      ageLevels,
      competitionType,
      inputVal: this.value,
    };
  },
};
</script>

<style lang="scss" scoped>

</style>
